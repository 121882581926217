<template>
  <main>
    <v-card class="login-card" :loading="loading" :disabled="disabled">
      <div class="form">
        <h3 class="title">Login</h3>
        <form @submit.prevent="pressed">
          <div class="inputs">
            <div class="input">
              <v-text-field
                label="Email"
                v-model="email"
                type="Email"
                outlined
                required
                dense
                class="mb-n3"
              ></v-text-field>
            </div>
            <div class="input">
              <v-text-field
                label="Password"
                v-model="password"
                type="Password"
                outlined
                required
                dense
              ></v-text-field>
            </div>
          </div>
          <div class="btns">
            <v-btn color="primary" class="white--text" type="submit">
              SignIn
              <v-icon right dark> mdi-arrow-right-circle </v-icon>
            </v-btn>
            <v-btn
              text
              color="blue"
              justify="right"
              @click="toggleForgetPasswordDialogue(true)"
              >Forget password</v-btn
            >
          </div>
          <div id="register" justify="center">
            <small>
              Don't You have an account? &nbsp;&nbsp;<router-link
                to="/register/"
                >Register</router-link
              >
            </small>
          </div>
          <v-snackbar :timeout="4000" v-model="snackbar" absolute bottom center>
            {{ snackbarText }}
          </v-snackbar>
        </form>
      </div>
      <div class="card-img">
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_gjmecwii.json"
          loop
          background="transparent"
          speed="1"
          style="width: 200px; height: 200px"
          autoplay
        ></lottie-player>
      </div>
    </v-card>

    <!-- forgetPassword dialog -->
    <v-dialog v-model="forgetPasswordDialog" width="300">
      <v-card class="fp-card" :loading="cardLAD" :disabled="cardLAD">
        <v-card-title>Forget Password</v-card-title>
        <v-card-subtitle>
          You will recive password reset email to registered email address
        </v-card-subtitle>
        <v-card-text>
          <form @submit.prevent="reset">
            <div class="input">
              <v-text-field
                label="Email"
                v-model="resetEmail"
                type="Email"
                outlined
                required
                dense
                class="mb-n3"
              ></v-text-field>
            </div>
            <v-btn color="primary" class="white--text" type="submit">
              Send Email
              <v-icon right dark> mdi-arrow-right-circle </v-icon>
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase";
import "firebase/auth";

export default {
  name: "login",
  layout: "authlay",
  data() {
    return {
      //snackbar
      snackbar: false,
      snackbarText: "No message",

      //form
      email: "",
      password: "",
      errors: "",

      //cards
      loading: false,
      disabled: false,

      //forgetPasswordDialog
      resetEmail: null,
      forgetPasswordDialog: false,
      cardLAD: false,
    };
  },

  methods: {
    ...mapActions(["signInWithEmail", "fetchProfile"]),

    async pressed() {
      console.log("signin functhion started running");

      this.loading = true;
      this.disabled = true;
      const authResult = await this.signInWithEmail({
        email: this.email,
        password: this.password,
      });
      console.log("authResult", authResult);

      if (authResult.user) {
        if (authResult.user.email == this.email) {
          this.snackbarText = "Logged in Successfully";
          this.snackbar = true;
          await this.fetchProfile();
          this.$router.push("/myaccount");
          this.loading = false;
          this.disabled = false;
        }
      }
      this.snackbarText = "Login Unsuccessfull";
      this.snackbar = true;

      this.loading = false;
      this.disabled = false;
    },
    toggleForgetPasswordDialogue(value) {
      this.resetEmail = this.email;
      this.forgetPasswordDialog = value;
    },
    async reset() {
      this.cardLAD = true;
      await firebase
        .auth()
        .sendPasswordResetEmail(this.resetEmail)
        .then(() => {
          this.snackbarText = "Reset link sent to " + this.resetEmail;
          this.snackbar = true;
        })
        .catch((error) => {
          this.snackbarText = error.message;
          this.snackbar = true;
        });
      this.forgetPasswordDialog = false;
      this.cardLAD = false;
    },
  },
};
</script>

<style lang='scss' scoped>
a {
  text-decoration: none;
}
main {
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcf5ff;
}
#register {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: rgb(69, 15, 94);
  margin-bottom: 10px;
}

.login-card {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  max-width: 580px;
  width: 70%;
  min-width: 260px;
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70px;
}
.fp-card {
  border-radius: 15px;
}
.form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}
.inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.input {
  width: 90%;
}
.card-img {
  width: 50%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 116, 116),
    rgb(221, 68, 221)
  );
  // background-image: url("../../assets/static/sign/auth-img.svg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 15px;
}
v-btn {
  background-color: green;
}
@media (max-width: 780px) {
  main {
    height: 92vh;
    padding: 0px;
  }
  .login-card {
    height: auto;
    width: 90%;
    display: flex;
    margin: 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .card-img {
    height: 200px;
    // display: none;
  }
}
</style>
